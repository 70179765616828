.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row.reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.col.reverse {
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-xs {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -webkit-flex-basis: 8.33333333%;
          flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-xs-2 {
  -webkit-flex-basis: 16.66666667%;
          flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-xs-3 {
  -webkit-flex-basis: 25%;
          flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -webkit-flex-basis: 33.33333333%;
          flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-xs-5 {
  -webkit-flex-basis: 41.66666667%;
          flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-xs-6 {
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -webkit-flex-basis: 58.33333333%;
          flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-xs-8 {
  -webkit-flex-basis: 66.66666667%;
          flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-xs-9 {
  -webkit-flex-basis: 75%;
          flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -webkit-flex-basis: 83.33333333%;
          flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-xs-11 {
  -webkit-flex-basis: 91.66666667%;
          flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-xs-12 {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.start-xs {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.end-xs {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.middle-xs {
  -webkit-align-items: center;
          align-items: center;
}

.bottom-xs {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.around-xs {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.between-xs {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.first-xs {
  -webkit-order: -1;
          order: -1;
}

.last-xs {
  -webkit-order: 1;
          order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 49rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-sm {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-sm {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .end-sm {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .middle-sm {
    -webkit-align-items: center;
            align-items: center;
  }

  .bottom-sm {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .around-sm {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .between-sm {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .first-sm {
    -webkit-order: -1;
            order: -1;
  }

  .last-sm {
    -webkit-order: 1;
            order: 1;
  }
}

@media only screen and (min-width: 64em) {
  .container {
    width: 65rem;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-md {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-md {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .end-md {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .middle-md {
    -webkit-align-items: center;
            align-items: center;
  }

  .bottom-md {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .around-md {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .between-md {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .first-md {
    -webkit-order: -1;
            order: -1;
  }

  .last-md {
    -webkit-order: 1;
            order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 76rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-lg {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    -webkit-flex-basis: 8.33333333%;
            flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    -webkit-flex-basis: 16.66666667%;
            flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    -webkit-flex-basis: 25%;
            flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-flex-basis: 33.33333333%;
            flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    -webkit-flex-basis: 41.66666667%;
            flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    -webkit-flex-basis: 50%;
            flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-flex-basis: 58.33333333%;
            flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    -webkit-flex-basis: 66.66666667%;
            flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    -webkit-flex-basis: 75%;
            flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-flex-basis: 83.33333333%;
            flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    -webkit-flex-basis: 91.66666667%;
            flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .start-lg {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .end-lg {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

  .middle-lg {
    -webkit-align-items: center;
            align-items: center;
  }

  .bottom-lg {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .around-lg {
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .between-lg {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .first-lg {
    -webkit-order: -1;
            order: -1;
  }

  .last-lg {
    -webkit-order: 1;
            order: 1;
  }
}
.flex-center{-webkit-justify-content:center!important;justify-content:center!important;-webkit-align-items:center!important;align-items:center!important}.flex-center__x{-webkit-justify-content:center!important;justify-content:center!important}.flex-center__y{-webkit-align-items:center!important;align-items:center!important}
.flex-column__lg,.flex-column__md,.flex-column__sm{display:none!important}.flex-column{-webkit-flex-direction:column;flex-direction:column}@media (max-width:48em){.flex-column__xs{-webkit-flex-direction:column;flex-direction:column}}@media (min-width:48em) and (max-width:64em){.flex-column__sm{-webkit-flex-direction:column;flex-direction:column}}@media (min-width:64em) and (max-width:75em){.flex-column__md{-webkit-flex-direction:column;flex-direction:column}}@media (min-width:75em){.flex-column__lg{-webkit-flex-direction:column;flex-direction:column}}
@media (max-width:48em){.hidden-xs{display:none!important}}@media (min-width:48em) and (max-width:64em){.hidden-sm{display:none!important}}@media (min-width:64em) and (max-width:75em){.hidden-md{display:none!important}}@media (min-width:75em){.hidden-lg{display:none!important}}
.visible-xs-block{display:none!important}@media (max-width:48em){.visible-xs-block{display:block!important}}.visible-sm-block{display:none!important}@media (min-width:48em) and (max-width:64em){.visible-sm-block{display:block!important}}.visible-md-block{display:none!important}@media (min-width:64em) and (max-width:75em){.visible-md-block{display:block!important}}.visible-lg-block{display:none!important}@media (min-width:75em){.visible-lg-block{display:block!important}}
.visible-xs-inline-block{display:none!important}@media (max-width:48em){.visible-xs-inline-block{display:inline-block!important}}.visible-sm-inline-block{display:none!important}@media (min-width:48em) and (max-width:64em){.visible-sm-inline-block{display:inline-block!important}}.visible-md-inline-block{display:none!important}@media (min-width:64em) and (max-width:75em){.visible-md-inline-block{display:inline-block!important}}.visible-lg-inline-block{display:none!important}@media (min-width:75em){.visible-lg-inline-block{display:inline-block!important}}
.visible-xs-inline{display:none!important}@media (max-width:48em){.visible-xs-inline{display:inline!important}}.visible-sm-inline{display:none!important}@media (min-width:48em) and (max-width:64em){.visible-sm-inline{display:inline!important}}.visible-md-inline{display:none!important}@media (min-width:64em) and (max-width:75em){.visible-md-inline{display:inline!important}}.visible-lg-inline{display:none!important}@media (min-width:75em){.visible-lg-inline{display:inline!important}}
.visible-xs{display:none!important}@media (max-width:48em){.visible-xs{display:-webkit-flex!important;display:flex!important}}.visible-sm{display:none!important}@media (min-width:48em) and (max-width:64em){.visible-sm{display:-webkit-flex!important;display:flex!important}}.visible-md{display:none!important}@media (min-width:64em) and (max-width:75em){.visible-md{display:-webkit-flex!important;display:flex!important}}.visible-lg{display:none!important}@media (min-width:75em){.visible-lg{display:-webkit-flex!important;display:flex!important}}
